import React from "react";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineClose } from "react-icons/ai";
import { convertCurrencyLocation } from "../helpers";
import { Loading } from "../Components/Loading";
import { Translation } from "../Translation";
import { appConfig } from "../config";
import "../App.css";

@inject("AppStore")
@observer
class Model extends React.Component {
  store = this.props.AppStore;

  render() {
    return (
      <div
        className={`modelSelector__item animate__animated animate__zoomIn ${
          this.props.store.selectedModel &&
          this.props.store.selectedModel.id === this.props.model.id &&
          "selected"
        }`}
        onClick={() => {
          this.props.store.selectModel(this.props.model);

          // setTimeout(() => {
          //   SmoothScrolling.scrollTo("content");
          // }, 400);
        }}
      >
        <div className="modelSelector__image">
          <img
            alt="cover"
            src={appConfig.api + this.props.model.cover.url}
            style={{ borderColor: this.props.model.color }}
          />
        </div>
        <div className="modelSelector__content">
          <div className="modelSelector__name">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.name }}
            ></span>
          </div>
          <div className="modelSelector__slogan">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.slogan }}
            ></span>
          </div>

          {/* <div className="modelSelector__length">
            <div className="modelSelector__content_title">
              <Translation t="Length" lang={this.store.page.lang} />
            </div>
            <div>
              {this.props.model.standard_spec &&
                this.props.model.standard_spec.length_m}
            </div>
          </div> */}
          <div className="modelSelector__price">
            <div className="modelSelector__content_title">
              <Translation t="From" lang={this.store.page.lang} />*
            </div>
            <div>
              {convertCurrencyLocation(
                this.props.model.starting_from,
                this.store.location,
                false
              )}
            </div>
          </div>
          {/* <div className="modelSelector__cta">
            <Translation
              t="CONFIGURE YOUR"
              lang={this.store.page.lang}
              params={{ name: this.props.model.name }}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

@observer
class Tabs extends React.Component {
  state = {
    selected: 0,
  };

  componentDidMount() {
    // console.log(this.props.items);
  }

  selectTab(i) {
    if (i === this.state.selected) {
      this.setState({ selected: -1 });
    } else {
      this.setState({ selected: i });
    }
  }

  render() {
    const { model } = this.props;

    if (!model) return <React.Fragment />;

    return (
      <div className="selectedModel__tabs">
        {model.tabs &&
          model.tabs.map((x, i) => (
            <div
              className={`selectedModel__tabs--item ${
                this.state.selected === i ? "selected" : ""
              }`}
              key={i}
            >
              <div
                className="selectedModel__tabs--title"
                onClick={() => this.selectTab(i)}
              >
                {" "}
                {this.state.selected === i ? (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="tabs-icon"
                    color={model.color}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="tabs-icon"
                    color={model.color}
                  />
                )}
                {x[0]}
              </div>
              <div
                className={`selectedModel__tabs--content ${
                  this.state.selected === i
                    ? "animate__animated animate__fadeIn"
                    : ""
                }`}
              >
                <pre dangerouslySetInnerHTML={{ __html: x[1] }} />
              </div>
            </div>
          ))}
      </div>
    );
  }
}

@inject("AppStore")
@observer
class Select extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    const { lang } = this.props.match.params;

    this.store.page.lang = lang || "en";
    this.store.page.suffix =
      this.store.page.lang === "en" ? "" : `_${this.store.page.lang}`;

    this.store.getLocation("default");
    this.store.loadConfig();
    this.store.getModels();
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}/${this.store.page.lang}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                textDecoration: "none",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {this.store.configuration && (
                <img
                  alt="logo"
                  src={appConfig.locale.api + this.store.configuration.logo.url}
                  className="logo"
                />
              )}
            </a>
          </div>
          {this.store.user ? (
            <div className="header-user">
              <span>{this.store.user.user.fullname} -&nbsp;</span>
              <a onClick={this.store.logout} alt="">
                LOGOUT
              </a>
            </div>
          ) : (
            <div className="header-user">
              <a href={`/${this.store.page.lang}/account`}>Account</a>
            </div>
          )}
        </div>
        <div className={`content ${this.store.selectedModel && "open"}`}>
          <div
            className="modelSelector__left"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div>
              <div className="modelSelector__title">
                <Translation
                  t="SELECT_YOUR"
                  lang={this.store.page.lang}
                  params={{ name: appConfig.locale.name }}
                />
              </div>
              <div
                className="modelSelector__titleSlogan"
                dangerouslySetInnerHTML={{
                  __html: this.store.configuration.slogan,
                }}
              ></div>
            </div>
            <div className="modelSelector__items">
              {this.store.list.map((x) => (
                <Model key={x.id} model={x} store={this.store} />
              ))}
            </div>
          </div>

          {this.store.selectedModel && (
            <div
              id="content"
              className="modelSelector__rightBackdrop"
              onClick={(e) => {
                this.store.selectedModel = undefined;
              }}
            >
              <div
                className={`modelSelector__right ${
                  this.store.selectAnimate
                    ? "animate__animated animate__fadeInRight"
                    : ""
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="modelSelector__right--close"
                  onClick={(e) => {
                    this.store.selectedModel = undefined;
                  }}
                >
                  <AiOutlineClose fontSize="32" />
                </div>
                <div
                  className="selectedModel__title"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: this.store.selectedModel.name,
                  }}
                ></div>
                <div className="selectedModel__tech">
                  <div className="selectedModel__subtitle">
                    <div
                      className="selectedModel__subtitle--top"
                      style={{
                        borderColor: `${this.store.selectedModel.color}`,
                      }}
                    >
                      <Translation
                        lang={this.store.page.lang}
                        t="TECHNICAL SPECIFICATIONS"
                      />
                    </div>
                  </div>

                  {this.store.selectedModel.standard_spec && (
                    <div className="selectedModel__specs">
                      {this.store.selectedModel.standard_spec.length_m && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Length"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.length_m}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.beam && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation t="Beam" lang={this.store.page.lang} />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.beam}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.beam_opened && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Beam with open Side Teracces"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.beam_opened}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.draft && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Draft"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.draft}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.displacement && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Displacement"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {
                              this.store.selectedModel.standard_spec
                                .displacement
                            }
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.passengers && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Passengers"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.passengers}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.berths && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Berths"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.berths}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.fuel && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Fuel Cap"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.fuel}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.range && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Range"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.range}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.water && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Fresh Water Cap"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.water}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec
                        .classification && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            <Translation
                              t="Classification"
                              lang={this.store.page.lang}
                            />
                          </div>
                          <div className="selectedModel__spec--text">
                            {
                              this.store.selectedModel.standard_spec
                                .classification
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="selectedModel__standard">
                  <div className="selectedModel__subtitle">
                    <div
                      className="selectedModel__subtitle--top"
                      style={{
                        borderColor: `${this.store.selectedModel.color}`,
                      }}
                    >
                      <Translation
                        t="STANDARD SPECIFICATIONS"
                        lang={this.store.page.lang}
                      />
                    </div>
                  </div>
                  <div className="selectedModel__standardspecs">
                    <div>
                      <pre>
                        {
                          this.store.selectedModel[
                            this.store.langify("standards")
                          ]
                        }
                      </pre>
                    </div>
                    <div>
                      <pre>
                        {
                          this.store.selectedModel[
                            this.store.langify("standards_right")
                          ]
                        }
                      </pre>
                    </div>

                    <Tabs model={this.store.selectedModel} />
                    <div
                      style={{ fontSize: 12, marginTop: 35, marginBottom: 100 }}
                    >
                      <Translation
                        t="STANDARD_INFO"
                        lang={this.store.page.lang}
                        params={{ name: appConfig.locale.name }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer modelSelector__footer">
          <div className="modelSelector__footer--left">
            <div style={{ fontSize: 12, paddingRight: 10, paddingLeft: 10 }}>
              <Translation t="SELECT_INFO" lang={this.store.page.lang} />
            </div>
          </div>
          <div className="modelSelector__footer--right">
            <div className="button-wrapper">
              {this.store.user && this.store.user.user.factory_order && (
                <div
                  className="modelSelector__cleanbutton"
                  style={{ marginRight: 15 }}
                >
                  <a href={`/${this.store.page.lang}/builds`}>
                    <Translation t="BUILDS" lang={this.store.page.lang} />
                  </a>
                </div>
              )}

              {this.store.user && this.store.user.user.service && (
                <div
                  className="modelSelector__cleanbutton"
                  style={{ marginRight: 15 }}
                >
                  <a href={`/${this.store.page.lang}/service`}>
                    <Translation t="SERVICE" lang={this.store.page.lang} />
                  </a>
                </div>
              )}

              {this.store.selectedModel && (
                <div className="modelSelector__cleanbutton">
                  <a
                    href={`/${this.store.page.lang}/build/${this.store.selectedModel.slug}`}
                  >
                    <Translation t="next" lang={this.store.page.lang} />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Select;
